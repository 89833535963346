import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决跳转到当前路由的冲突报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由配置
const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/view/Main'),
    children: [
      {
        path: '',
        redirect: '/index'
      },
      {
        path: '/index',
        component: () => import('@/view/home/Home')
      },
      {
        path: '/info',
        component: () => import('@/view/Info/Index')
      },
      {
        name: 'houseDetail',
        path: '/houseDetail',
        component: () => import('@/view/houseDetail/Index'),
        props($route){
          return {
            assetId:$route.query.assetId
          }
        }
      },
      {
        path: '/allHouse',
        component: () => import('@/view/houseList/AllHouse')
      },
      {
        path: '/mapFindHouse',
        component: () => import('@/view/houseList/MapFindHouse')
      },
      {
        path: '/helpFindHouse',
        component: () => import('@/view/helpFindHouse/HelpFindHouse')
      },
      {
        path: '/servicePoint',
        component: () => import('@/view/servicePoint/ServicePoint')
      },
      {
        path: '/shop',
        component: () => import('@/view/houseList/Shop')
      },
      {
        path: '/factory',
        component: () => import('@/view/houseList/Factory')
      },
      {
        path: '/officeBuilding',
        component: () => import('@/view/houseList/OfficeBuilding')
      },
      {
        path: '*',
        redirect: '/index'
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
