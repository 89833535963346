import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Antd, { Carousel,Collapse } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Antd)
Vue.use(Carousel)
Vue.use(Collapse)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
